// CheckAssetBalancesComponent.js
import React, { useState } from 'react';
import { Modal, Button, Input, Radio, notification } from 'antd';
import { usePostAssetActionMutation } from '../services/localApi';

import './common.css';



// const { Option } = Select;

const AssetControlComponent = () => {
  const [postAssetAction] = usePostAssetActionMutation();

  const [isVisible, setIsVisible] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [selectedAction, setSelectedAction] = useState('');

  const [amount, setAmount] = useState('');
  const [coinRadioValue, setCoinRadioValue] = useState('usdt');
  const [coinTextValue, setCoinTextValue] = useState('');

  const [fromWalletVisible, setFromWalletVisible] = useState(false);
  const [fromWalletRadioValue, setFromWalletRadioValue] = useState('cross');
  const [fromWalletTextValue, setFromWalletTextValue] = useState('');

  const [toWalletVisible, setToWalletVisible] = useState(false);
  const [toWalletRadioValue, setToWalletRadioValue] = useState('cross');
  const [toWalletTextValue, setToWalletTextValue] = useState('');


  const handleOk = async () => {
      setIsVisible(false);

      let tempFromWallet;
      if (fromWalletRadioValue !== 'input') {
        tempFromWallet = fromWalletRadioValue;
      } else {
        tempFromWallet = fromWalletTextValue;
      }

      let tempToWallet;
      if (toWalletRadioValue !== 'input') {
        tempToWallet = toWalletRadioValue;
      } else {
        tempToWallet = toWalletTextValue;
      }

      let tempCoin;
      if (coinRadioValue !== 'input') {
        tempCoin = coinRadioValue;
      } else {
        tempCoin = coinTextValue;
      }

      try {
        const response = await postAssetAction({ user: localStorage.getItem('access_token'),
          selectedAction,
          fromWallet: tempFromWallet,
          toWallet: tempToWallet,
          coin: tempCoin,
          amount }).unwrap();
        notification.success({
          message: 'Success',
          description: `${response}`,
        });
      } catch (err) {
        notification.error({
          message: 'Error',
          description: `${selectedAction} error : ${err?.data}`,
        });
      }
    };

  const handleCancel = () => {
    setIsVisible(false);
  };

  const handleButtonBorrow = (e) => {
    setIsVisible(true);
    setSelectedAction('borrow');
    setDialogTitle('Borrow');

    setCoinRadioValue('usdt');
    setCoinTextValue('');

    setFromWalletVisible(true);
    setFromWalletTextValue('');
    setFromWalletRadioValue('cross');

    setToWalletVisible(false);
  };
  const handleButtonRepay = (e) => {
    setIsVisible(true);
    setSelectedAction('repay');
    setDialogTitle('Repay');

    setCoinRadioValue('usdt');
    setCoinTextValue('');

    setFromWalletVisible(true);
    setFromWalletTextValue('');
    setFromWalletRadioValue('cross');

    setToWalletVisible(false);
  };
  const handleButtonSubcribe = (e) => {
    setIsVisible(true);
    setSelectedAction('subcribe');
    setDialogTitle('Subcribe earn');
    setCoinRadioValue('input');
    setCoinTextValue('');
    setFromWalletVisible(false);
    setToWalletVisible(false);
  };
  const handleButtonRedeem = (e) => {
    setIsVisible(true);
    setSelectedAction('redeem');
    setDialogTitle('Redeem earn');
    setCoinRadioValue('input');
    setCoinTextValue('');
    setFromWalletVisible(false);
    setToWalletVisible(false);
  };
  const handleButtonTransfer = (e) => {
    setIsVisible(true);
    setSelectedAction('transfer');
    setDialogTitle('Transfer');

    setCoinRadioValue('input');
    setCoinTextValue('');

    setFromWalletVisible(true);
    setFromWalletRadioValue('spot');
    setFromWalletTextValue('');

    setToWalletVisible(true);
    setToWalletRadioValue('cross');
    setToWalletTextValue('');
  };

    return (
      <div>
      <div style={{ display: 'flex', fontWeight: 'bold', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px', fontSize: '15px' }}>
        <Button className="normal-button" onClick={handleButtonBorrow}> Borrow </Button>
        <Button className="normal-button" onClick={handleButtonRepay}> Repay </Button>
        <Button className="normal-button" onClick={handleButtonSubcribe}> Subcribe </Button>
        <Button className="normal-button" onClick={handleButtonRedeem}> Redeem </Button>
        <Button className="normal-button" onClick={handleButtonTransfer}> Transfer </Button>
        </div>
        <Modal
          title={dialogTitle}
          visible={isVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          width={600}
        >

          {fromWalletVisible && (
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontWeight: 'bold', fontSize: '15px', marginTop: '5px' }}>
            <div style={{ width: '120px' }}> From : </div>
            {/* <Input style={{ margin: '5px' }} value={fromWallet} onChange={(e) => setFromWallet(e.target.value)} /> */}
            <Radio.Group
              value={fromWalletRadioValue}
              onChange={(e) => {
                setFromWalletRadioValue(e.target.value);
            }}
              style={{ margin: '5px' }}
            >
              {selectedAction === 'transfer' && (
              <Radio value="spot">Spot</Radio>
            )}
              <Radio value="cross">Cross</Radio>
              <Radio value="input">
                <Input
                  value={fromWalletTextValue}
                  style={{ width: '100px' }}
                  placeholder="Isolated wallet"
                  onChange={(e) => setFromWalletTextValue(e.target.value)}
                  disabled={fromWalletRadioValue !== 'input'}
                />
              </Radio>

              {selectedAction === 'transfer' && (
              <Radio value="future">Future</Radio>
              )}
            </Radio.Group>
          </div>
          )}
          {toWalletVisible && (
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontWeight: 'bold', fontSize: '15px', marginTop: '5px' }}>
            <div style={{ width: '120px' }}> To : </div>
            {/* <Input style={{ margin: '5px' }} value={toWallet} onChange={(e) => setFromWallet(e.target.value)} /> */}
            <Radio.Group
              value={toWalletRadioValue}
              onChange={(e) => {
                setToWalletRadioValue(e.target.value);
            }}
              style={{ margin: '5px' }}
            >
              {selectedAction === 'transfer' && (
              <Radio value="spot">Spot</Radio>
            )}
              <Radio value="cross">Cross</Radio>
              <Radio value="input">
                <Input
                  value={toWalletTextValue}
                  style={{ width: '100px' }}
                  placeholder="Isolated wallet"
                  onChange={(e) => setToWalletTextValue(e.target.value)}
                  disabled={toWalletRadioValue !== 'input'}
                />
              </Radio>
              {selectedAction === 'transfer' && (
              <Radio value="future">Future</Radio>
            )}
            </Radio.Group>
          </div>
          )}

          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontWeight: 'bold', fontSize: '15px', marginTop: '5px' }}>
            <div style={{ width: '120px' }}> Coin : </div>
            {/* <Input style={{ margin: '5px' }} value={coin} onChange={(e) => setCoin(e.target.value)} /> */}
            <Radio.Group
              value={coinRadioValue}
              onChange={(e) => {
                setCoinRadioValue(e.target.value);
            }}
              style={{ margin: '5px' }}
            >
              <Radio value="usdt">USDT</Radio>
              <Radio value="eth">ETH</Radio>
              <Radio value="input">
                <Input
                  value={coinTextValue}
                  style={{ width: '100px' }}
                  placeholder="Other asset"
                  onChange={(e) => setCoinTextValue(e.target.value)}
                  disabled={coinRadioValue !== 'input'}
                />
              </Radio>
            </Radio.Group>
          </div>

          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontWeight: 'bold', fontSize: '15px', marginTop: '5px' }}>
            <div style={{ width: '120px' }}> Amount : </div>
            <Input style={{ margin: '5px' }} placeholder={((selectedAction === 'subcribe') || (selectedAction === 'redeem')) ? 'Leave blank for all' : 'Amount'} type="number" value={amount} onChange={(e) => setAmount(e.target.value)} />
          </div>

        </Modal>
      </div>
    );
  };

export default AssetControlComponent;
