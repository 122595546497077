import React, { useEffect, useState } from 'react';
import { Table} from 'antd';
// import { GoogleLogin } from 'react-google-login';
import {  useGetPositionsQuery } from '../services/localApi';
import './common.css';
import useTokenRefresh from '../services/useTokenRefresh';
import { bColumns, hColumns } from './common';
import CheckAssetBalancesComponent from './CheckAssetBalancesComponent';
import CheckSymbolSpreadComponent from './CheckSymbolSpreadComponent';
import CheckFundingRateHistoryComponent from './CheckFundingRateHistoryComponent';
import PostTradeComponent from './PostTradeComponent';
import TradeInforComponent from './TradeInforComponent';
import AssetControlComponent from './AssetControlComponent';

const Positions = () => {
  useTokenRefresh();
  // const {
  //   isLoggedIn, setIsLoggedIn, user, setUser,
  // } = useStateContext();
  const [initialLoad, setInitialLoad] = useState(true);
  const { data, isFetching, refetch, error } = useGetPositionsQuery(localStorage.getItem('access_token'));
  const [lastUpated, setLastUpdated] = useState('');
  const [BPositions, setBPositions] = useState([]);
  const [HPositions, setHPositions] = useState([]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      refetch();
    }, 60000); // 30 seconds

    if (initialLoad && !isFetching && data) {
      setInitialLoad(false);
    }
    if (data) {
      if (data.B) setBPositions(data.B);
      if (data.H) setHPositions(data.H);
      setLastUpdated(new Date().toLocaleString('en-GB'));
    }

    return () => clearInterval(intervalId);
  }, [refetch, isFetching, data, initialLoad]);

  return (
    <div className="tables-container">

      <div style={{ marginRight: '20px', flex: '0.75', minWidth: '300px' }}>
        <PostTradeComponent />
        <AssetControlComponent />
        <CheckAssetBalancesComponent />
        <CheckSymbolSpreadComponent />
        <CheckFundingRateHistoryComponent />
        <TradeInforComponent />
      </div>

      {/* First table */}
      {/* uncomment below 3 lines to merge 2 positions table in 1 div */}
      {/* <div className="table-wrapper" style={{ flex: '2' }}> */}
        {/* <HistoricalSpreadComponent style={{ width: '100%' }} /> */}
        {/* <div style={{ display: 'flex', flexDirection: 'row', marginTop: '20px' }}> */}
          <div style={{ flex: '1' }}>
            <div className="status-grid">
              <div className="status-div">
                B positions
              </div>
              <div className="status-div">
                <div>Ratio :</div>
                <div style={{ paddingRight: '150px' }}>
                  {BPositions.ratio}
                  {' '}
                  %
                </div>
              </div>
              <div className="status-div">
                <div>Margin balance :</div>
                <div style={{ paddingRight: '150px' }}>
                  {BPositions.margin_balance}
                  {' '}
                  $
                </div>
              </div>
              <div className="status-div">
                <div>Wallet balance :</div>
                <div style={{ paddingRight: '150px' }}>
                  {BPositions.wallet_balance}
                  {' '}
                  $
                </div>
              </div>
              <div className="status-div">
                <div>LONG :</div>
                <div style={{ paddingRight: '150px', color: 'green' }}>
                  {BPositions.long}
                  {' '}
                  $
                </div>
              </div>
              <div className="status-div">
                <div>SHORT :</div>
                <div style={{ paddingRight: '150px', color: 'red' }}>
                  {BPositions.short}
                  {' '}
                  $
                </div>
              </div>
              <div className="status-div">
                <div>Cross margin :</div>
                <div style={{ paddingRight: '150px' }}>
                  {BPositions.cross_margin}
                </div>
              </div>
            </div>
            <Table
              columns={bColumns}
              dataSource={BPositions.positions}
              pagination={false}
              size="small"
                // onChange={onChange}
              style={{ marginRight: '20px' }}
            />
          </div>
          <div style={{ flex: '1' }}>
            <div className="status-grid">
              <div className="status-div">
                H positions
              </div>
              <div className="status-div">
                <div>Ratio :</div>
                <div style={{ paddingRight: '150px' }}>
                  {HPositions.ratio}
                  {' '}
                  %
                </div>
              </div>
              <div className="status-div">
                <div>Margin balance :</div>
                <div style={{ paddingRight: '150px' }}>
                  {HPositions.margin_balance}
                  {' '}
                  $
                </div>
              </div>
              <div className="status-div">
                <div>Wallet balance :</div>
                <div style={{ paddingRight: '150px' }}>
                  {HPositions.wallet_balance}
                  {' '}
                  $
                </div>
              </div>
              <div className="status-div">
                <div>LONG :</div>
                <div style={{ paddingRight: '150px', color: 'green' }}>
                  {HPositions.long}
                  {' '}
                  $
                </div>
              </div>
              <div className="status-div">
                <div>SHORT :</div>
                <div style={{ paddingRight: '150px', color: 'red' }}>
                  {HPositions.short}
                  {' '}
                  $
                </div>
              </div>
              <div className="status-div">
                <div>updated :</div>
                <div style={{ paddingRight: '150px', color: 'blue' }}>
                  {lastUpated}
                  {' '}
                  $
                </div>
              </div>
            </div>
            <Table
              columns={hColumns}
              dataSource={HPositions.positions}
              pagination={false}
              size="small"
            />
          </div>
        </div>
      // </div>

    // </div>

  );
};

export default Positions;

