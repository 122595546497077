// CheckAssetBalancesComponent.js
import React, { useState } from 'react';
import { Table, Button } from 'antd';
import { useGetFrMutation, useGetEarnLoanAPYMutation } from '../services/localApi';
import { earnLoanColumns, fundingRateColumns } from './common';
import './common.css';

const CheckFundingRateHistoryComponent = () => {
    const [textBoxCheckFundingRate, setTextBoxCheckFundingRate] = useState('');
    const [getFr] = useGetFrMutation();
    const [getEarnLoan] = useGetEarnLoanAPYMutation();

  const [frData, setFrData] = useState(null);
  const [earnLoanData, setEarnLoanData] = useState(null);

      const handleButtonCheckFundingRate = async () => {
        setEarnLoanData(null);
        setFrData(null);
        // eslint-disable-next-line
        const res = await getFr({ symbol: textBoxCheckFundingRate, user: localStorage.getItem('access_token') });
        setFrData(res.data);
      };

      const handleButtonGetEarnLoan = async () => {
        setEarnLoanData(null);
        setFrData(null);
        // eslint-disable-next-line
        const res = await getEarnLoan({ symbol: textBoxCheckFundingRate, user: localStorage.getItem('access_token') });
        setEarnLoanData(res.data);
      };

    return (
      <div>
        <div style={{ display: 'flex', alignItems: 'center', fontWeight: 'bold', fontSize: '15px', marginTop: '10px', position: 'relative' }}>
          <div style={{ width: '100px' }}>FR / Earn  : </div>
          <input style={{ marginLeft: '5px' }} type="text" value={textBoxCheckFundingRate} onChange={(e) => { setTextBoxCheckFundingRate(e.target.value); }} />
          <div style={{ position: 'absolute', right: '0' }}>
            <Button className="normal-button" style={{width:'60px'}} onClick={handleButtonGetEarnLoan}>APY</Button>
            <Button className="normal-button" style={{ marginLeft: '5px' }} type="primary" onClick={handleButtonCheckFundingRate}> Get fr  </Button>
          </div>

        </div>
        {frData
                && (
                <div>
                  <div style={{ fontSize: '15px' }}>{ frData.description } </div>
                  <Table
                    columns={fundingRateColumns}
                    dataSource={frData.response}
                    size="small"
                    style={{ marginTop: '20px' }}
                    pagination={false}
                  />
                  <div style={{ fontSize: '15px' }}>{ frData.summary } </div>
                </div>
)}

        {earnLoanData
                && (
                <div>
                  <div style={{ fontSize: '15px' }}>{ earnLoanData.description } </div>
                  <Table
                    columns={earnLoanColumns}
                    dataSource={earnLoanData.response}
                    size="small"
                    style={{ marginTop: '20px' }}
                    pagination={false}
                  />

                </div>
)}

      </div>
    );
  };

export default CheckFundingRateHistoryComponent;
