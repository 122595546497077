// CheckAssetBalancesComponent.js
import React, { useState } from 'react';
import { Button, notification} from 'antd';
import { useResetTradeMutation, useListTradeMutation, useStopTradeMutation } from '../services/localApi';

import './common.css';

const TradeInforComponent = () => {
  const [getListTrade] = useListTradeMutation();
  const [resetTrade] = useResetTradeMutation();
  const [stopTrade] = useStopTradeMutation();
  const [currentTradeList, setCurrentTradeList] = useState(null);

  const handleButtonListTrade = async () => {
    // eslint-disable-next-line
    const temp = await getListTrade({ user: localStorage.getItem('access_token') });
    setCurrentTradeList(temp.data);
  };
  const handleButtonReset = async () => {
    // eslint-disable-next-line
    const temp = await resetTrade({ user: localStorage.getItem('access_token') });
    // console.log(temp);
    setCurrentTradeList(temp.data);
  };

  const handleStopClick = async (index) => {
    try {
      const temp = await stopTrade({ user: localStorage.getItem('access_token'), id: index }).unwrap(); // Using unwrap to get the response directly
      notification.success({
        message: 'Success',
        description: `Stop thread ${ index } successfully.`,
      });
    } catch (stopError) {
      // Handle error here
      notification.error({
        message: 'Error',
        description: `Unable to stop thread ${ index } !! `,
      });
    }
  };

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', fontWeight: 'bold', fontSize: '15px' }}>
        <Button className="normal-button" style={{ margin: '5px', marginLeft: '0px' }} onClick={handleButtonListTrade}> List thread </Button>
        <Button className="normal-button" style={{ margin: '5px' }} onClick={handleButtonReset}> Reset </Button>
      </div>
      {currentTradeList && (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'left', fontWeight: 'bold', color: 'blue', marginTop: '5px' }}>
        {String(currentTradeList).split('\n').map((line, index) => (
          <div key={index}>
            <Button danger style={{ margin: '2px' }} size="small" onClick={() => handleStopClick(index)}>Stop</Button>{line}
          </div>
            ))}
      </div>
        )}


    </div>
  );
};

export default TradeInforComponent;
